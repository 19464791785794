import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
    token: string | null;
    isAuthenticated: boolean;
}
const initialState: AuthState = {
    token: null,
    isAuthenticated: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLogin: (state, action) => {
            state.token = action.payload.token;
            state.isAuthenticated = true;
        },
        setLogout: (state) => {
            state.token = null;
            state.isAuthenticated = false;
        },
        setToken: (state, action) => {
            state.token = action.payload.token;
        },
    },
});

export const { setLogin, setLogout, setToken } = authSlice.actions;
export default authSlice.reducer;
