import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userProfileReducer from "@/store/userProfileSlice";
import authReducer from "@/store/authenticationSlice";
import userPostReducer from "@/store/userPostSlice";
import sidebarReducer from "@/store/sidebarClickSlice";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";

const createNoopStorage = () => {
    return {
        getItem(_key: any) {
            return Promise.resolve(null);
        },
        setItem(_key: any, value: any) {
            return Promise.resolve(value);
        },
        removeItem(_key: any) {
            return Promise.resolve();
        },
    };
};
const storage =
    typeof window !== "undefined"
        ? createWebStorage("local")
        : createNoopStorage();

const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
    console.log("Dispatching:", action);
    let result = next(action);
    console.log("Next state:", store.getState());
    return result;
};

const rootReducer = combineReducers({
    sidebar: sidebarReducer,
    userProfile: userProfileReducer,
    authProfile: authReducer,
    userPost: userPostReducer,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["authProfile", "userProfile", "userPost", "sidebar"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = () => {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                    ],
                },
            }).concat(loggerMiddleware),
    });
};

export const store = makeStore();
export const persistor = persistStore(store);
// Check if the initial state  is correctly initialized
console.log(store.getState());

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
