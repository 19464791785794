import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function extractMessage(input: string): string {
    // Use regex to match the content inside optional square brackets
    const regex = /\['(.*?)'\]/;
    const match = input.match(regex);

    // If a match is found, return the first captured group
    if (match && match[1]) {
        return match[1];
    }
    return input; // Return a default message if no match is found
}

export function getCsrfToken(): string | undefined {
    // Assuming the CSRF token is stored in a cookie named 'csrf_access_token'
    return document.cookie
        .split("; ")
        .find((row) => row.startsWith("csrf_access_token="))
        ?.split("=")[1];
}

export function getHeaders(strict_token: boolean = true): Headers {
    const headers = new Headers();
    const csrfToken = getCsrfToken();
    headers.append("Content-Type", "application/json");
    if (csrfToken) {
        headers.append("X-CSRF-TOKEN-ACCESS", csrfToken);
    } else {
        console.error("CSRF token not found in cookies");
        console.log(" the current url is ", window.location.href);
        if (strict_token) throw new Error("CSRF token not found in cookies");
    }
    return headers;
}

export function getFileUploadHeaders(): Headers {
    const headers = new Headers();
    const csrfToken = getCsrfToken();
    if (csrfToken) headers.append("X-CSRF-TOKEN-ACCESS", csrfToken);
    return headers;
}

export function pathExistsInURL(targetPath: string): boolean {
    const parsedUrl = new URL(window.location.href);
    if (parsedUrl.pathname.startsWith(targetPath)) {
        return true;
    }
    return false;
}
