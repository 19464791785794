import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Post, UserMedia, SocialAccount } from "@/types/user_post";

const initialState: Post = {
    content: "",
    media: [] as UserMedia[],
    sm_accounts: [] as SocialAccount[],
};

// The fundamental assumption here is the user will
// only have one post at a time. This is a very basic
// assumption and can be changed as per the requirements.

const userPostSlice = createSlice({
    name: "userPost",
    initialState,
    reducers: {
        clearUserPost: (state) => {
            state.content = "";
            state.media = [];
            state.sm_accounts = [];
        },
        addUserContent: (state, action: PayloadAction<string>) => {
            state.content = action.payload;
        },
        clearUserContent: (state) => {
            state.content = "";
        },
        addUserMedia: (state, action: PayloadAction<UserMedia>) => {
            state.media = [action.payload, ...state.media];
        },
        deleteUserMedia: (state, action: PayloadAction<string>) => {
            state.media = state.media.filter(
                (media) => media.id !== action.payload,
            );
        },
        clearUserMedia: (state) => {
            state.media = [];
        },
        addAccount: (state, action: PayloadAction<SocialAccount>) => {
            state.sm_accounts = [...state.sm_accounts, action.payload];
        },
        deleteAccount: (state, action: PayloadAction<SocialAccount>) => {
            state.sm_accounts = state.sm_accounts.filter(
                (account: SocialAccount) => account.id !== action.payload.id,
            );
        },
        clearAccounts: (state) => {
            state.sm_accounts = [];
        },
    },
});

export const {
    clearUserPost,
    addUserContent,
    clearUserContent,
    addUserMedia,
    deleteUserMedia,
    clearUserMedia,
    addAccount,
    deleteAccount,
    clearAccounts,
} = userPostSlice.actions;
export default userPostSlice.reducer;
