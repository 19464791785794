import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserProfile } from "@/types/user_profile";

const initialState: UserProfile = {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    is_active: false,
    is_registered: false,
    is_subscriber: false,
    is_verified: false,
    last_login: null,
    deactivated_at: null,
    registered_at: null,
    verified_at: null,
    subscribed_at: null,
    is_insta_connected: false,
};

const userProfileSlice = createSlice({
    name: "userProfile",
    initialState,
    reducers: {
        setUserProfile: (state, action: PayloadAction<UserProfile>) => {
            state.id = action.payload.id;
            state.first_name = action.payload.first_name;
            state.last_name = action.payload.last_name;
            state.email = action.payload.email;
            state.is_active = action.payload.is_active;
            state.is_registered = action.payload.is_registered;
            state.is_subscriber = action.payload.is_subscriber;
            state.is_verified = action.payload.is_verified;
            state.last_login = action.payload.last_login;
            state.deactivated_at = action.payload.deactivated_at;
            state.registered_at = action.payload.registered_at;
            state.verified_at = action.payload.verified_at;
            state.subscribed_at = action.payload.subscribed_at;
            state.is_insta_connected = action.payload.is_insta_connected;
        },
        clearUserProfile: (state) => {
            state.id = null;
            state.first_name = null;
            state.last_name = null;
            state.email = null;
            state.is_active = false;
            state.is_registered = false;
            state.is_subscriber = false;
            state.is_verified = false;
            state.last_login = null;
            state.deactivated_at = null;
            state.registered_at = null;
            state.verified_at = null;
            state.subscribed_at = null;
            state.is_insta_connected = false;
        },
        updateUserProfile: (
            state,
            action: PayloadAction<Partial<UserProfile>>,
        ) => {
            state.id = action.payload.id ?? state.id;
            state.first_name = action.payload.first_name ?? state.first_name;
            state.last_name = action.payload.last_name ?? state.last_name;
            state.email = action.payload.email ?? state.email;
            state.is_active = action.payload.is_active ?? state.is_active;
            state.is_registered =
                action.payload.is_registered ?? state.is_registered;
            state.is_subscriber =
                action.payload.is_subscriber ?? state.is_subscriber;
            state.is_verified = action.payload.is_verified ?? state.is_verified;
            state.last_login = action.payload.last_login ?? state.last_login;
            state.deactivated_at =
                action.payload.deactivated_at ?? state.deactivated_at;
            state.registered_at =
                action.payload.registered_at ?? state.registered_at;
            state.verified_at = action.payload.verified_at ?? state.verified_at;
            state.subscribed_at =
                action.payload.subscribed_at ?? state.subscribed_at;
            state.is_insta_connected =
                action.payload.is_insta_connected ?? state.is_insta_connected;
        },
    },
});

export const { setUserProfile, clearUserProfile, updateUserProfile } =
    userProfileSlice.actions;
export default userProfileSlice.reducer;
