import { createSlice } from "@reduxjs/toolkit";

interface SidebarState {
    activeLink: string | null;
}
const initialState: SidebarState = {
    activeLink: "users/dashboard",
};

const sideBarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        setActiveLink: (state, action) => {
            state.activeLink = action.payload.activeLink;
        },
        removeActiveLink: (state) => {
            state.activeLink = null;
        },
        resetActiveLink: (state) => {
            state.activeLink = "users/dashboard";
        },
    },
});

export const { setActiveLink, removeActiveLink, resetActiveLink } =
    sideBarSlice.actions;
export default sideBarSlice.reducer;
